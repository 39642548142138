import React, { Suspense,useEffect } from "react";
import { BrowserRouter, Routes, Route  } from "react-router-dom";

import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Customers = React.lazy(() => import("./pages/Customers"));
const CustomerEdit = React.lazy(() => import("./pages/CustomerEdit"));
const Employees = React.lazy(() => import("./pages/Employees"));
const EmployeeEdit = React.lazy(() => import("./pages/EmployeeEdit"));
const Products = React.lazy(() => import("./pages/Products"));
const Orders = React.lazy(() => import("./pages/Orders"));
const ProductEdit = React.lazy(() => import("./pages/ProductEdit"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const BlankPage = React.lazy(() => import("./pages/BlankPage"));
const Login = React.lazy(() => import("./pages/Login"));
const Report = React.lazy(() => import("./pages/ReportPage"));
const Default = React.lazy(() => import("./pages/Default"));
const Suport = React.lazy(() => import("./pages/Suport"));


function App() {

  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.href="/bisl";
    }
  }, []);

  return (
    <BrowserRouter basename="/bisl">
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:customerId" element={<CustomerEdit />} />
              <Route path="/employees" element={<Employees />} />
              <Route path="/employee/:employeeId" element={<EmployeeEdit />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:productId" element={<ProductEdit />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/analytics" element={<BlankPage />} />
              <Route path="/discount" element={<BlankPage />} />
              <Route path="/inventory" element={<BlankPage />} />
              <Route path="/report" element={<Report />} />
              <Route path="/default" element={<Default />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/suport" element={<Suport/>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
